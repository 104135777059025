import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta:{
      title:'pdd',
    },
    component:() => import('@/views/home.vue')
  },
  {
    path: "/order",
    name: "order",
    meta:{
      title:'pdd',
    },
    component:() => import('@/views/order.vue')
  },
  {
    path: "/login",
    name: "login",
    meta:{
      title:'登录页面',
    },
    component:() => import('@/views/auth/login.vue')
  },
  {
    path: "/test",
    name: "test",
    meta:{
      title:'test',
    },
    component:() => import('@/views/test.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
