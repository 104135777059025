//import {useRouter} from 'vue-router'
import router from "@/router/index"

export function commonFunction () {
    //const router = useRouter(); //  等于$router
    const Jump = (url: any) => {
        /*console.log("这是封装的函数");
        console.log('正在跳转')*/
        router.push(url);
    };
    return {Jump}
}