//我要用到的一些接口
import request from "@/utils/request"

//获取用户信息
export function user_info() {
    return request({
        url: "/users/info",
        method: "GET",
    })
}

